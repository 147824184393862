import styled from 'styled-components'

const Title = styled.h1`
  line-height: 1.35;
  margin-top: 20px;
  margin-bottom: 20px;
  padding-top: 30px;
  padding-bottom: 10px;
`
export default Title;
