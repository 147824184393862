import React from 'react'

import Code from './code';
import Title from './title'
import Subtitle from './subtitle'
import SmallTitle from './smalltitle'
import Paragraph from './pragraph'
import Li from './li'

export default {
  h1: props => <Title {...props} />,
  h2: props => <Subtitle {...props} />,
  h3: props => <SmallTitle {...props} />,
  p: props => <Paragraph {...props} />,
  li: props => <Li {...props} />,
  pre: props => <Code {...props} />,
}