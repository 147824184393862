import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import Markdown from 'react-markdown';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import Layout from '../components/layout';
import mdxComponents from '../components/mdx';
import Head from '../components/head';

const PostTitle = styled.h1`
    text-align: center;
    margin: 20px 0;
`;

const Banner = styled.div`
    text-align: center;
    p {
        margin-bottom: 12px;
    }
`;

export default function PostPage({ data }) {
    const { title, bannerUrl, bannerCredit, description } =
        data.mdx.frontmatter;
    return (
        <Layout>
            <Head
                title={title}
                description={description}
                bannerUrl={bannerUrl}
                isBlogPost
            />
            <article>
                <PostTitle>{title}</PostTitle>
                {bannerUrl ? (
                    <Banner>
                        <img src={bannerUrl} alt={'banner'} />
                        {bannerCredit ? (
                            <Markdown>{bannerCredit}</Markdown>
                        ) : null}
                    </Banner>
                ) : null}
                <MDXProvider components={mdxComponents}>
                    <MDXRenderer>{data.mdx.body}</MDXRenderer>
                </MDXProvider>
            </article>
        </Layout>
    );
}

export const pageQuery = graphql`
    query BlogPostQuery($id: String) {
        mdx(id: { eq: $id }) {
            id
            body
            frontmatter {
                title
                bannerUrl
                bannerCredit
                description
            }
        }
    }
`;
